import Vue from 'vue'
import Vuex from 'vuex'

import layouts from '@/modules/layouts'
import products from '@/modules/products'
import user from '@/modules/user'
import newslatter from '@/modules/newslatter'
import cart from '@/modules/cart'
import cartAnonimo from '@/modules/cartAnonimo'
import favorite from '@/modules/favorite'
import infocupo from '@/modules/infocupo'
import qec from '@/modules/qec'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loaderClover: false,
    innerWidthPage: 0,
    esFE: '0',
    showModal423: false,
    dataModal423: {
      title: 'Lo sentimos',
      descripcion: 'Parece que algo salio mal, por favor recarga el sitio.',
      routeName: 'PaymentEmail',
    },
  },
  mutations: {
    setEsFE(state, newVal) {
      state.esFE = newVal ? '1' : '0'
    },
  },
  actions: {
    changeEsFE({ commit }, newVal) {
      commit('setEsFE', newVal)
    },
  },
  modules: {
    layouts,
    products,
    user,
    newslatter,
    cart,
    cartAnonimo,
    favorite,
    infocupo,
    qec,
  },
})
