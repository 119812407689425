<template>
  <div id="footer-simple">
    <div class="container-footer-newslatter">
      <div class="footer-newslatter">
        <news-letter />
      </div>
    </div>
    <div class="footer-grid">
      <div class="col-md-3 pe-md-4">
        <div class="d-flex ps-3">
          <img
            alt="Logo clover"
            src="@/assets/icons/logo-clover.svg"
            class="mb-5"
          />
          <div class="d-flex d-md-none mx-auto">
            <a href="https://www.facebook.com/CloverBolsos/">
              <img
                class="pe-4"
                alt="Logo facebook"
                src="@/assets/icons/logo-facebook.svg"
              />
            </a>
            <a href="https://www.instagram.com/cloverbolsos/?hl=es">
              <img
                class="pe-4"
                alt="Logo instagram"
                src="@/assets/icons/logo-instagram.svg"
              />
            </a>
          </div>
        </div>
        <p class="mb-5 mb-md-4 ps-3">
          El amor por el color lo podrás ver en cada uno de nuestros bolsos.
          Nuestros diseños son contemporáneos, funcionales y con un estilo que
          los hace únicos ¡Cada bolso es una historia que quieres conocer!.
        </p>
        <div class="d-none d-md-flex ps-3 mb-4">
          <a href="https://www.facebook.com/CloverBolsos/">
            <img
              class="pe-4"
              alt="Logo facebook"
              src="@/assets/icons/logo-facebook.svg"
            />
          </a>
          <a href="https://www.instagram.com/cloverbolsos/?hl=es">
            <img
              class="pe-4"
              alt="Logo instagram"
              src="@/assets/icons/logo-instagram.svg"
            />
          </a>
        </div>
        <div
          class="d-flex ps-3 pb-2 justify-content-center justify-content-md-start"
        >
          <a target="_blank" href="https://www.sic.gov.co/">
            <img
              src="@/assets/icons/industriaycomercio.svg"
              style="filter: brightness(0)"
            />
          </a>
        </div>
        <div
          class="my-4 d-flex flex-wrap justify-content-center"
          style="background-color: white;"
        >
          <img
            src="@/assets/icons/mediosPago/visa.svg"
            alt="icono visa"
            style="max-width: 40px; margin: .3rem;"
          />
          <img
            src="@/assets/icons/mediosPago/mastercard.svg"
            alt="icono mastercard"
            style="max-width: 32px; margin: .3rem;"
          />
          <img
            src="@/assets/icons/mediosPago/diners.svg"
            alt="icono diners"
            style="max-width: 66px; margin: .3rem;"
          />
          <img
            src="@/assets/icons/mediosPago/pse.png"
            alt="icono pse"
            style="max-width: 33px; margin: .3rem;"
          />
          <img
            src="@/assets/icons/mediosPago/amex.svg"
            alt="icono american express"
            style="max-width: 32px; margin: .3rem;"
          />
          <img
            src="@/assets/icons/mediosPago/efecty.svg"
            alt="icono efecty"
            style="max-width: 58px; margin: .3rem;"
          />
          <img
            src="@/assets/icons/mediosPago/nequi.svg"
            alt="icono nequi"
            style="max-width: 72px; margin: .3rem;"
          />
          <img
            src="@/assets/icons/mediosPago/daviplata.svg"
            alt="icono daviplata"
            style="max-width: 38px; margin: .3rem;"
          />
          <img
            src="@/assets/icons/mediosPago/rappi.svg"
            alt="icono rappi"
            style="max-width: 39px; margin: .3rem;"
          />
          <img
            src="@/assets/icons/mediosPago/addi.svg"
            alt="icono addi"
            style="max-width: 47px; margin: .3rem;"
          />
          <img
            src="@/assets/icons/mediosPago/logo_quac_morado.svg"
            alt="icono quac"
            style="max-width: 60px; margin-right: .3rem"
          />
          <img
            src="@/assets/icons/mediosPago/sistecredito.svg"
            alt="icono sistecredito"
            style="max-width: 82px; margin-right: .3rem"
          />
        </div>
        <p class="ps-md-3 mb-4 text-center" style="font-size: 13px">
          Todos los derechos reservados Baguer S.A.S.
        </p>
      </div>
      <div class="col-12 col-md-9 d-flex flex-wrap">
        <div class="col-12 col-md-4 col-md-4 mb-4">
          <p style="font-size: 18px; color: black">Atención al cliente</p>
          <div class="divider-footer" />
          <p class="item-footer ps-3">
            <b>Whatsapp</b> <br />
            <a
              href="https://api.whatsapp.com/send?phone=573208743430&text=Hola!%20Quiero%20m%C3%A1s%20informaci%C3%B3n%20sobre%20el%20producto"
              >320 874 3430</a
            >
          </p>
          <p class="item-footer ps-3">
            <b>Servicio al cliente</b> <br />
            Línea Nacional:
            <a href="tel:018000423550">01 8000 423550</a>
            - Opción 2
            <br />
            Línea Móvil: <a href="tel:3003870181">300 387 0181</a> - Opción 2
          </p>
          <p class="item-footer ps-3">
            Correo electrónico <br />
            <a href="mailto:servicioalcliente@cloverbolsos.com"
              >servicioalcliente@cloverbolsos.com</a
            >
          </p>
          <p class="item-footer ps-3">
            <b>Horarios de atención</b> <br />
            Lunes a sábado 8:30 a.m. - 7:00 p.m.
          </p>
          <p class="item-footer ps-3">
            <b>Radicación PQRS</b> <br />
            <a target="_blank" href="https://grupobaguer.com/pqrs.php?BAGUER"
              >Radica tu PQR aquí</a
            >
          </p>
        </div>
        <div class="col-12 col-md-4 col-md-4 mb-4">
          <p style="font-size: 18px; color: black">Información</p>
          <div class="divider-footer" />
          <ul class="list-footer">
            <li>
              <a
                href="https://preaprobado.quac.co/?store_business=baguer&slug_store=clover"
                target="_blank"
                class="item-footer"
              >
                Solicita tu cupo
              </a>
            </li>
            <li>
              <a href="https://portalpagos.cloverbolsos.co" class="item-footer">
                Paga tu cuota
              </a>
            </li>
            <li>
              <router-link :to="{ name: 'Tiendas' }" class="item-footer">
                Encuentra una tienda
              </router-link>
            </li>
            <!-- <li>
              <router-link :to="{ name: 'Franquicia' }" class="item-footer">
                Franquicias
              </router-link>
            </li> -->
            <li v-for="(data, index) in dataInfo" :key="index">
              <router-link
                :to="{
                  name: data.tipo_pagina ? 'PreguntasFrecuentes' : 'HabeasData',
                  params: { politica: data.slug },
                }"
                class="item-footer"
              >
                {{ data.titulo }}
              </router-link>
            </li>
            <li class="liblack">
              <router-link
                :to="{ name: 'Devoluciones' }"
                class="item-footer"
                style="color: black"
              >
                <b>Devoluciones</b>
              </router-link>
            </li>
            <li class="liblack">
              <router-link
                :to="{ name: 'RastrearPedido' }"
                class="item-footer"
                style="color: black"
              >
                <b>Rastrear pedido</b>
              </router-link>
            </li>
          </ul>
        </div>
        <div class="col-12 col-md-4 mb-4">
          <p style="font-size: 18px; color: black">Políticas</p>
          <div class="divider-footer" />
          <ul class="list-footer">
            <li v-for="(data, index) in dataPolitics" :key="index">
              <router-link
                :to="{ name: 'HabeasData', params: { politica: data.slug } }"
                class="item-footer"
              >
                {{ data.titulo }}
              </router-link>
            </li>
            <li>
              <a
                href="https://centroderelevo.gov.co/632/w3-channel.html"
                target="_blank"
                class="item-footer"
              >
                Servicio para personas ciegas y sordociegas
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NewsLetter from '@/components/footer/NewsLetter.vue'

import { mapActions, mapState } from 'vuex'
import { GA4SignUp } from '@/GA4/events'
import { sendFBQEvent } from '@/fbq'
export default {
  components: { NewsLetter },
  data() {
    return {
      emailNewslatter: '',
      invalidEmailNewslatter: false,
      activeStepNewslatter: 1,
      dayNewslatter: null,
      monthNewslatter: null,
      telNumber: '',
      invalidTelNumber: false,
      showNumber: false,
      tyc: true,
      dataPolitics: [],
      dataInfo: [],
    }
  },
  computed: {
    ...mapState('layouts', ['menu']),
    validateEmail() {
      /* eslint-disable */
      let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(this.emailNewslatter)
    },
    validateDate() {
      return this.dayNewslatter != null && this.monthNewslatter != null
    },
    validatePhone() {
      if (!this.showNumber) {
        return true
      } else {
        const re = /^3[0-9]{9}/
        return re.test(this.telNumber) && this.telNumber.length < 11
      }
    },
  },
  watch: {
    async activeStepNewslatter() {
      if (this.activeStepNewslatter == 4) {
        await setTimeout(() => {
          this.activeStepNewslatter = 1
          this.emailNewslatter = ''
          this.dayNewslatter = null
          this.monthNewslatter = null
          this.telNumber = ''
          this.showNumber = false
        }, 15000)
      }
    },
  },
  async mounted() {
    await this.axios({
      method: 'GET',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      url: '/layout/get_footer',
    })
      .then(response => {
        this.dataInfo = response.data.bloque1
        this.dataPolitics = response.data.bloque2
      })
      .catch(error => {
        console.log(error)
      })
  },
  methods: {
    ...mapActions('newslatter', ['sendSoliNewsLatter']),
    async sendRequestNesLatter() {
      if (
        this.validateEmail &&
        this.validateDate &&
        !(this.showNumber && !this.validatePhone)
      ) {
        this.$store.state.loaderClover = true
        let data = {
          email: this.emailNewslatter,
          day: this.dayNewslatter,
          month: this.monthNewslatter,
          sendSMS: this.showNumber ? 1 : 0,
          tel: this.telNumber,
        }
        await this.sendSoliNewsLatter(data).then(() => {
          this.$store.state.loaderClover = false
          if (!this.errorSolicitud) {
            this.activeStepNewslatter = 4
            GA4SignUp('newslatter')
            sendFBQEvent(this, 'Subscribe', {})
          }
        })
      }
    },
    validateBlur(blur) {
      if (blur == 'email') {
        this.invalidEmailNewslatter = !this.validateEmail
      } else if (blur == 'tel') {
        this.invalidTelNumber = !this.validatePhone
      }
    },
  },
}
</script>
