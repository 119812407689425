import state from './state'
import * as actions from '../qec/actions'

const namespaced = true

export default {
  namespaced,
  actions,
  state,
}
