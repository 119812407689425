import axios from 'axios'
const urlbase = 'https://api.appget.co/api/v1/qec/'
// const urlbase = 'https://testapidev.co/api/v1/qec/'
export const qecAxios = axios.create({
  baseURL: urlbase,
  headers: {
    'content-type': 'application/json',
    accept: 'application/json',
  },
})

export default qecAxios
